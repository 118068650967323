import * as React from "react";
import { PageProps, graphql } from "gatsby";

import { ImageMediaItemUrl } from "@types";
import { YoastSEOType } from "../../assets/Types";
import DefaultPollenLayout from "../../layouts/DefaultPollenLayout";
import parse from "html-react-parser";
import PollenEthicsConsultConfirmationContainer from "../../containers/PollenEthicsConsultConfirmationContainer/PollenEthicsConsultConfirmationContainer";
import SEO from "gatsby-plugin-wpgraphql-seo";
import { deIndexSeo } from "../../utils/deIndexSeo";

type DataProps = {
  wpPage: {
    seo: YoastSEOType;
    template: {
      pollenEthicsConsultConfirmation: {
        pollenEthicsConsultConfirmationBg?: string;
        pollenEthicsConsultConfirmationCopy?: string;
        pollenEthicsConsultConfirmationGraphic?: ImageMediaItemUrl;
      };
    };
  };
};

const PollenEthicsConsultConfirmationPage: React.FC<PageProps<DataProps>> = ({
  data,
  location,
}) => {
  const { wpPage } = data;
  const {
    template: {
      pollenEthicsConsultConfirmation: {
        pollenEthicsConsultConfirmationCopy,
        pollenEthicsConsultConfirmationGraphic,
        pollenEthicsConsultConfirmationBg,
      },
    },
  } = wpPage;
  const ReactElements = parse(pollenEthicsConsultConfirmationCopy);

  return (
    <DefaultPollenLayout noFooter noBanner pathname={location.pathname}>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <PollenEthicsConsultConfirmationContainer
        pollenConfirmationBG={pollenEthicsConsultConfirmationBg}
        pollenConfirmationImage={pollenEthicsConsultConfirmationGraphic}
        pollenConfirmationImageAltText={
          pollenEthicsConsultConfirmationGraphic.altText
        }
        reactElements={ReactElements}
      />
    </DefaultPollenLayout>
  );
};

export const POLLEN_ETHICS_CONSULT_CONFIRMATION_PAGE_QUERY = graphql`
  query PollenConsultConfirmationPageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
      template {
        ... on WpPollenEthicsConsultConfirmationTemplate {
          pollenEthicsConsultConfirmation {
            pollenEthicsConsultConfirmationBg
            pollenEthicsConsultConfirmationCopy
            pollenEthicsConsultConfirmationGraphic {
              altText
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;

export default PollenEthicsConsultConfirmationPage;
