import React from "react";
import { ImageMediaItemUrl } from "@types";

import styles from "./PollenEthicsConsultConfirmationContainer.module.scss";

type PollenEthicsConsultConfirmationContainerProps = {
  pollenConfirmationBG?: string;
  pollenConfirmationImage?: ImageMediaItemUrl;
  pollenConfirmationImageAltText?: string;
  reactElements;
};

const PollenEthicsConsultConfirmationContainer: React.FC<PollenEthicsConsultConfirmationContainerProps> =
  ({
    pollenConfirmationBG,
    pollenConfirmationImage,
    pollenConfirmationImageAltText,
    reactElements,
  }) => {
    return (
      <section className={styles.pollenEthicsConsultConfirmationContainer}>
        <div
          className={styles.pollenEthicsConsultConfirmationBg}
          style={{ background: pollenConfirmationBG }}
        ></div>
        <section className={styles.pollenEthicsConsultConfirmationWrapper}>
          <figure
            className={styles.pollenEthicsConsultConfirmationImageWrapper}
          >
            <img
              src={pollenConfirmationImage?.localFile.publicURL}
              alt={pollenConfirmationImageAltText}
            />
          </figure>
          <div className={styles.pollenEthicsConsultConfirmationDivider}></div>
          {reactElements}
        </section>
      </section>
    );
  };

export default PollenEthicsConsultConfirmationContainer;
